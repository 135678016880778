import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import UserService from "../services/UserService";
import EnrollService from "../services/EnrollService";
import AcademyService from "../services/AcademyService";
import { connect } from "react-redux";
import SummaryFilter from "../components/summary/summary-filter";
import SummaryTable from "../components/summary/summary-table";
import { useUI, usePageUI } from '../app/context/ui';

const Summary = ({ user, campus }) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [rows, setRows] = useState([]);
  const [sections, setSections] = useState([]);



  const summaryReports = async (data) => {
    blockUI.current.open(true, 'Estamos Generando...');
    const url = 'https://reports.colegiosmonserrat.info/'; //prod
    // const url = 'http://reports-local.colegiosmonserrat.info:7013/'; //Local
    try {
      const response = await axios.get(`${url}reports-excel?type=report_card&section_id=${data.section_id}&report_card_type=section&report_card_format=${data.report_card_type}&period_id=${data.period_id}&school_year_id=${data.school_year_id}&campus_id=${campus.id}&owner_id=${user.id}`);
      setRows(response.data);
      blockUI.current.open(false);
    
    } catch (error) {
      blockUI.current.open(false);
      console.log(error);
      alertUI.current.open(true, 'Error', 'danger');
    }
  }

  const listSections = async (yearId) => {
    try {
      const response = await UserService.listSectionsByCampus(
        yearId,
        campus.id
      );
      setSections(response.data);
    } catch (error) {}
  };

  const changeSection = (val) => {
    setRows([]);
  }

  useEffect(() => {
    UserService.init(user.accessToken, campus.id);
    EnrollService.init(user.accessToken);
  }, [campus, user]);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Sabana</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {user.school ? user.school : "YAKHANA"}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Sabana
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <SummaryFilter
            listSections={listSections}
            sections={sections}
            summaryReports={summaryReports}
            campus={campus}
            changeSection={changeSection}
          />
          <SummaryTable
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus,
    filters: state.filters
  };
};

export default connect(mapStateToProps)(Summary);
