import { Formik, Form } from "formik";
import * as yup from "yup";
import FilterDocuments from "../Filters/FilterDocuments";

const validationSchema = yup.object({
  school_year_id: yup
    .number("Elija el año lectivo")
    .required("El año lectivo es obligatorio"),
  section_id: yup
    .number("Elija la sección")
    .required("La sección es obligaria"),
  report_card_type: yup
    .string("Elija el documento")
    .required("El curso es obligatorio"),
  period_id: yup
    .number("Elija el Bimestre")
    .required("El bimestre es obligatorio"),
});

const initialValues = {
  school_year_id: "",
  section_id: "",
  report_card_type: "",
  period_id: "",
};

const SummaryFilter = ({
  listSections,
  sections,
  summaryReports,
  campus,
  changeSection
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            values.campus_id = campus.id;
            summaryReports(values);
          }}
          enableReinitialize={true}
        >
          {(props) => (
            <Form>
              <FilterDocuments
                {...props}
                listSections={listSections}
                sections={sections}
                changeSection={changeSection}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SummaryFilter;
