// Import all components here
import StudentPage from '../pages/StudentPage';
import PaymentPage from '../pages/PaymentPage';
import LoginPage from '../pages/LoginPage';
import Signup from './Authentication/signup';
import ForgotPassword from './Authentication/forgotpassword';
import NotFound from './Authentication/404';
import InternalServer from './Authentication/500';
import SchoolYearPage from '../pages/SchoolYearPage';
import EnrollPage from '../pages/EnrollPage';
import SectionPage from '../pages/SectionPage';
import CampusPage from '../pages/CampusPage';
import UserPage from '../pages/UserPage';
import ProductPage from '../pages/ProductPage';
import SettingPage from '../pages/SettingPage';
import EnrollPaymentPage from '../pages/EnrollPaymentPage';
import EnrollEditPage from '../pages/EnrollEditPage';
import ExpensePage from '../pages/ExpensePage';
import DashboardPage from '../pages/DashboardPage';
import ReportsPage from '../pages/ReportsPage';
import AgentPage from '../pages/AgentPage';
import Teachers from '../pages/Teachers';
import NotesPage from '../pages/NotesPage';
import Profiles from '../pages/Profiles';
import Tasks from '../pages/Tasks';
import Documents from '../pages/Documents';
import Summary from '../pages/Summary';

const Routes = [
  {
      path: '/payments',
      name: 'payments',
      // roles: ['admin'],
      exact: true,
      pageTitle: 'Payments',
      component: PaymentPage
  },
  {
      path: '/students',
      name: 'students',
      exact: true,
      pageTitle: 'Students',
      component: StudentPage
  },
  {
      path: '/users',
      name: 'users',
      exact: true,
      pageTitle: 'Usuarios',
      component: UserPage
  },
  {
      path: '/',
      name: 'dashboard',
      exact: true,
      pageTitle: 'Dashboard',
      component: DashboardPage
  },
  {
      path: '/login',
      name: 'login',
      exact: true,
      pageTitle: 'Tables',
      component: LoginPage
  },
  {
      path: '/signup',
      name: 'signup',
      exact: true,
      pageTitle: 'Tables',
      component: Signup
  },
  {
      path: '/forgotpassword',
      name: 'forgotpassword',
      exact: true,
      pageTitle: 'Tables',
      component: ForgotPassword
  },
  {
      path: '/notfound',
      name: 'notfound',
      exact: true,
      pageTitle: 'Tables',
      component: NotFound
  },
  {
      path: '/internalserver',
      name: 'internalserver',
      exact: true,
      pageTitle: 'Tables',
      component: InternalServer
  },
  {
      path: '/schoolyear',
      name: 'schoolyear',
      exact: true,
      pageTitle: 'School year',
      component: SchoolYearPage
  },
  {
      path: '/enrolls',
      name: 'matricula',
      exact: true,
      roles: ['super-admin', 'admin', 'manager', 'secretary'],
      pageTitle: 'Matricula',
      component: EnrollPage
  },
  {
      path: '/enrolls/:id/payments',
      name: 'matricula',
      roles: ['super-admin', 'admin', 'manager', 'secretary'],
      exact: true,
      pageTitle: 'Matricula',
      component: EnrollPaymentPage,
  },
  {
    path: '/enrolls/:id/edit',
    name: 'matricula',
    exact: true,
    pageTitle: 'Matricula',
    component: EnrollEditPage,
},
  {
    path: '/sections',
    name: 'secciones',
    exact: true,
    pageTitle: 'Secciones',
    component: SectionPage,
  },
  {
    path: '/campuses',
    name: 'campuses',
    exact: true,
    pageTitle: 'Sedes',
    component: CampusPage,
  },
  {
    path: '/products',
    name: 'products',
    exact: true,
    pageTitle: 'Productos',
    component: ProductPage,
  },
  {
    path: '/settings',
    name: 'settings',
    exact: true,
    pageTitle: 'Ajustes',
    component: SettingPage,
  },
  {
    path: '/expenses',
    name: 'expenses',
    exact: true,
    pageTitle: 'Egresos',
    component: ExpensePage,
  },
  {
    path: '/reports',
    name: 'resports',
    roles: ['super-admin', 'admin', 'manager', 'secretary'],
    exact: true,
    pageTitle: 'Reportes',
    component: ReportsPage,
  },
  {
    path: '/agents',
    name: 'agents',
    exact: true,
    pageTitle: 'Apoderados',
    component: AgentPage,
  },
  {
    path: '/teachers',
    name: 'teachers',
    exact: true,
    pageTitle: 'Profesores',
    component: Teachers,
  },
  {
    path: '/teachers/:id/courses',
    name: 'profiles',
    exact: true,
    pageTitle: 'Perfiles',
    component: Profiles,
  },
  {
    path: '/notes',
    name: 'notes',
    exact: true,
    pageTitle: 'Registros',
    component: NotesPage,
  },
  {
    path: '/tasks',
    name: 'tasks',
    exact: true,
    pageTitle: 'Tareas',
    component: Tasks,
  },
  {
    path: '/documents',
    name: 'documents',
    exact: true,
    pageTitle: 'Documentos',
    component: Documents,
  },
  {
    path: '/summary',
    name: 'sammary',
    exact: true,
    pageTitle: 'Sabana',
    component: Summary,
  },
];

export default Routes;
