import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Stack,
  TableContainer,
  Paper,
  Box,
  Toolbar,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@mui/material";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { upperCase } from "lodash";

const DocumentTable = ({ rows, downloadDocument, downloadDocumentBySection, downloadAvg, avgRows, openModal, closeModal }) => {
  const [process, setProcess] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClose = () => {
    setOpen(false);
    setProcess(false);
    closeModal(false);
  };

  return (
    <Box
      style={{ width: "100%" }}
      sx={{
        width: "100%",
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        { rows.length > 0 && 
            <Button
            variant="contained"
            color="info"
            onClick={() => {
              downloadDocumentBySection();
              setProcess(true);
            }}
          >
            Descarga Libreta por Seccion
          </Button>}
          {
            process && 
            <Button
              variant="contained"
              color="error"
              sx={{ml:1}}
              onClick={() => {
                downloadAvg();
                // setOpen(true);
              }}
            >
              Ver promedios
          </Button>
          }
      </Toolbar>
      <TableContainer component={Paper}>
        <Table sx={{ maxHeight: 440 }} size="small" aria-label="a dense table" >
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Numero de Matricula</TableCell>
              <TableCell align="center">DNI</TableCell>
              <TableCell align="center">Apellidos y Nombres</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell padding="checkbox">{index + 1}</TableCell>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">{row.userDni}</TableCell>
                <TableCell>{upperCase(row.userFullName)}</TableCell>
                <TableCell align="center">
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => downloadDocument(row.id)}
                    >
                      Descargar
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        <Dialog
          maxWidth="lg"
          open={openModal}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title" >
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn btn-info"
              table="table-to-xls"
              filename="promedios"
              sheet="promedios"
              buttonText="Descargar Promedios"/>
          </DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
          <Table size="lg" aria-label="a dense table" id="table-to-xls" sx={{ maxHeight: 440 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">DNI</TableCell>
                <TableCell align="center">APELLIDOS Y NOMBRES</TableCell>
                <TableCell align="center">PROMEDIO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {avgRows?.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell padding="checkbox">{index + 1}</TableCell> */}
                <TableCell align="center">{row.student.dni}</TableCell>
                <TableCell align="left">{row.student.fullName}</TableCell>
                <TableCell>{row.avg}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Subscribe</Button>
          </DialogActions> */}
        </Dialog>
      </>
    </Box>
  );
};

export default DocumentTable;
