import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Stack,
  TableContainer,
  Paper,
  Box,
  Toolbar,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@mui/material";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { upperCase } from "lodash";

const SummaryTable = ({ rows, downloadDocument, downloadDocumentBySection, downloadAvg, avgRows }) => {
  const [process, setProcess] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClose = () => {
    setOpen(false);
    setProcess(false);
  };

  return (
    <Box
      style={{ width: "100%" }}
      sx={{
        width: "100%",
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        { rows.length > 0 && 
            <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn btn-info"
            table="table-to-xls"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Download as XLS"/>}
       
      </Toolbar>
      <TableContainer component={Paper}>
        <Table sx={{ maxHeight: 440 }} size="small" aria-label="a dense table" id="table-to-xls" >
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">DNI</TableCell>
              <TableCell align="center">Apellidos y Nombres</TableCell>
              {
                rows[0]?.courses.map((col, i) => (
                  String(col.id).includes("a") &&
                  <TableCell colSpan={4}>{col.name}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell padding="checkbox">{index + 1}</TableCell>
                <TableCell align="center">{row.student.dni}</TableCell>
                <TableCell>{upperCase(row.student.fullName)}</TableCell>
                {
                  row.courses?.map((col, i) =>(
                    String(col.id).includes("a") && (
                      <>
                        <TableCell>{col.scores.pb1.letterGrade}</TableCell>
                        <TableCell>{col.scores.pb2.letterGrade}</TableCell>
                        <TableCell>{col.scores.pb3.letterGrade}</TableCell>
                        <TableCell>{col.scores.pb4.letterGrade}</TableCell>
                      </>
                        
                    )  
                  ))
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title" >
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn btn-info"
              table="table-to-xls"
              filename="promedios"
              sheet="promedios"
              buttonText="Descargar Promedios"/>
          </DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
          <Table size="lg" aria-label="a dense table" id="table-to-xls" sx={{ maxHeight: 440 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">DNI</TableCell>
                <TableCell align="center">APELLIDOS Y NOMBRES</TableCell>
                <TableCell align="center">PROMEDIO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {avgRows?.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell padding="checkbox">{index + 1}</TableCell> */}
                <TableCell align="center">{row.student.dni}</TableCell>
                <TableCell align="left">{row.student.fullName}</TableCell>
                <TableCell>{row.avg}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Subscribe</Button>
          </DialogActions> */}
        </Dialog>
      </>
    </Box>
  );
};

export default SummaryTable;
