import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import UserService from "../services/UserService";
import EnrollService from "../services/EnrollService";
import AcademyService from "../services/AcademyService";
import { connect } from "react-redux";
import DocumentTable from "../components/document/document-table";
import DocumentFilter from "../components/document/document-filter";
import { useUI, usePageUI } from '../app/context/ui';

const Documents = ({ user, campus, filters, dispatch }) => {
  const academyService = new AcademyService();
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [year, setYear] = useState("");
  const [rows, setRows] = useState([]);
  const [avg, setAvg] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sections, setSections] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filter, setFilter] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const listStudents = async (data) => {
    setFilter(data);
    try {
      const response = await EnrollService.listEnrollsDocuments(data.school_year_id, data.section_id, data.campus_id);
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };


  const downloadDocument = async (enrollId) => {
    blockUI.current.open(true, 'Estamos generando...');
    
    try {
      const response = await academyService.downloadOne(filter.section_id, filter.report_card_type, enrollId, filter.period_id);
      let urlDoc = response.data.resourceUrl;
      const fileName = urlDoc.split('/').pop();

      let file = document.createElement('a');
      file.href = urlDoc;
      file.setAttribute('download', fileName)
      file.setAttribute('target', '_blank')
      file.click();

      blockUI.current.open(false);
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
    }
  };

  const downloadDocumentBySection = async() => {
    setAvg([]);
    try {
      const response = await academyService.createTask(filter.section_id, filter.report_card_type, filter.period_id, campus.id, year, user.id);
      if (response) {
        Swal.fire({
          title: "Buen trabajo!",
          html: `
            Estamos procesando tu solicitud, puedes ver el progreso,
            <a href="/tasks" target="_blank">aqui</a>
          `,
          icon: "success",
        });
      }
    } catch (error) {
      
    }
  }

  const downloadAvg = () => {
    blockUI.current.open(true, 'Estamos generando...');
    axios({
      method: 'get',
      url: `https://reports.colegiosmonserrat.info/sabana?type=report_card&section_id=${filter.section_id}&report_card_type=section&report_card_format=${filter.report_card_type}&period_id=${filter.period_id}&school_year_id=${year}&campus_id=${campus.id}&owner_id=${user.id}`,
      responseType: 'json'
    })
      .then(function (response) {
       setAvg(response.data.sort((a, b) => b.avg - a.avg));
       blockUI.current.open(false);
       setOpenModal(true);
      });
  }

  const listSections = async (yearId) => {
    setYear(yearId);
    try {
      const response = await UserService.listSectionsByCampus(
        yearId,
        campus.id
      );
      setSections(response.data);
    } catch (error) {}
  };

  const changeSection = (val) => {
    setRows([]);
    setAvg([]);
  }

  const closeModal = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    UserService.init(user.accessToken, campus.id);
    EnrollService.init(user.accessToken);
  }, [campus, user]);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Documentos</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {user.school ? user.school : "YAKHANA"}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Documentos
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <DocumentFilter
            listSections={listSections}
            sections={sections}
            listStudents={listStudents}
            campus={campus}
            changeSection={changeSection}
          />
          <DocumentTable
            rows={rows}
            downloadDocument={downloadDocument}
            downloadDocumentBySection={downloadDocumentBySection}
            downloadAvg={downloadAvg}
            avgRows={avg}
            openModal={openModal}
            closeModal={closeModal}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus,
    filters: state.filters
  };
};

export default connect(mapStateToProps)(Documents);
